import * as React from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Heading, Label } from 'components/primitives'
import Margin from 'components/Margin'
import Location from 'components/Input/Location'
import Input, { YesNoRadio, YesNoTriggers } from 'components/Input'
import { Form } from 'components/FormManager'
import { CallTypes } from 'types/callstore'
import { useReduxCallStoreActions, useCallEvent } from 'hooks/events'
import { Condition } from 'components/fields'
import LockoutDispatch from './LockoutDispatch'
import MultiChoiceActions from './MultiChoiceActions'
import Divider from 'components/Divider'
import { FormApi } from 'final-form'
import { EventTypes } from 'types/events'
import MultipleChoice from 'components/Input/MultipleChoice'
import LockoutEvent from './LockoutEvent'
import Waivers from 'components/Waivers'
import { TicketCharges } from 'types/ticket'
import { VehicleSelect } from 'components/Select'
import Error from 'components/fields/Error'
import { isRequired } from 'utils/validators'
import useCreateServiceEvent from '../../../hooks/events/useCreateServiceEvent'
import { useDispatch, useSelector } from 'react-redux'
import { selectCustomVinInput, selectFormsState, selectTicket } from 'redux/appStore'
import { setCurrentEvent } from 'redux/currentEvent/currentEventSlice'

declare module 'types/form' {
  export interface Forms {
    [CallTypes.KEYS]: FormApi
  }
}

const LockoutForm: React.FC = () => {
  const { t } = useTranslation()
  const { lockout } = useSelector(selectFormsState) as any
  const { update } = useReduxCallStoreActions()
  const lockoutEvent = useCallEvent(EventTypes.LOCKOUT)
  const towEvent = useCallEvent(EventTypes.TOW)
  const { vehicle } = useSelector(selectTicket)
  const createServiceEvent = useCreateServiceEvent({ eventType: EventTypes.LOCKOUT } || null)
  const dispatch = useDispatch()
  const customVinInput = useSelector(selectCustomVinInput)

  React.useEffect(() => {
    const submitLockout = async () => {
      const event = await createServiceEvent({
        ...lockout,
      })
      dispatch(setCurrentEvent({ eventType: EventTypes.LOCKOUT, newEvent: event.data }))
    }
    // This is for automatically submitting the lockout/keys workflow form IF a tow event already exists
    if (!lockoutEvent && towEvent) {
      submitLockout()
    }
  }, [towEvent])

  if (lockoutEvent && lockoutEvent.event) return <LockoutEvent {...lockoutEvent.event} />

  return (
    <Form name={CallTypes.KEYS} schema={lockoutSchema} initialValues={lockout} autoSaveSync={(lockout) => update({ lockout })}>
      <Heading as="h5" spacing="md">
        {t('lockout.title')}
      </Heading>

      <Margin spacing="md">
        <Label> {t('fees')}</Label>
        <Waivers charge={TicketCharges.KEY_LOCK_OUT_CHARGE} />
      </Margin>
      <Margin spacing="md">
        <Label> {t('features')}</Label>
      </Margin>
      <VehicleSelect label={t('labels.requiredVehicle')} name="vehicle" />
      {customVinInput ? (
        <Input.Hidden name="customVin" defaultValue={customVinInput} />
      ) : (
        <Input.Hidden name="vehicle.value" validate={isRequired(t('required.vehicle'))} />
      )}
      <Margin spacing="md">
        <Location.Vehicle name="serviceLocations.vehicleLocation" />
        <Input.Hidden name="serviceLocations.vehicleLocation" validate={isRequired('Vehicle location is required')} />
      </Margin>
      <Divider style={{ margin: '24px 0' }} />
      <YesNoRadio name="lostKeys" label={t('lockout.keys.lost')} />

      <Condition when="lostKeys" is={YesNoTriggers.YES}>
        <YesNoRadio
          name="personalBelongings"
          trigger={YesNoTriggers.EITHER}
          label={t('lockout.belongings')}
          validate={isRequired('Personal Belongings field required')}
        >
          <MultiChoiceActions />
          <Error name="personalBelongings" />
        </YesNoRadio>
      </Condition>

      <Condition when="lostKeys" is={YesNoTriggers.NO}>
        <MultipleChoice
          name="keysLocation"
          label={t('lockout.keys.location')}
          choices={{ trunk: t('lockout.trunk.label'), cabin: t('lockout.cabin') }}
          validate={isRequired(t('Keys Location is required'))}
        />
        <Error name="keysLocation" />
        <Condition when="keysLocation" is="trunk">
          <Heading as="h5">{t('lockout.trunk.hasAccess')}</Heading>
          <Label spacing="md"> {t('lockout.trunk.trunkNote')}</Label>
          <YesNoRadio name="trunkAccess" validate={isRequired('Trunk Access field required')} />
          <Condition when="trunkAccess" is="yes">
            <LockoutDispatch />
          </Condition>
          <Condition when="trunkAccess" is="no">
            <YesNoRadio
              name="personalBelongings"
              trigger={YesNoTriggers.EITHER}
              label={t('lockout.belongings')}
              validate={isRequired('Personal Belongings field required')}
            >
              <MultiChoiceActions />
              <Error name="personalBelongings" />
            </YesNoRadio>
          </Condition>
        </Condition>
        <Condition when="keysLocation" is="cabin">
          <LockoutDispatch />
        </Condition>
      </Condition>
    </Form>
  )
}

export default LockoutForm

const lockoutSchema = Yup.object({
  lostKeys: Yup.string().nullable().required('Lost keys field required'),
  keysLocation: Yup.string().nullable(),
  personalBelongings: Yup.string().nullable(),
  trunkAccess: Yup.string().nullable(),
})
