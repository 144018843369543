import * as React from 'react'
import { Branch, BranchInfoFragment } from 'types/global'
import { useFieldValue } from 'hooks/utils'
import ExchangeSearch from './ExchangeSearch'
import ExchangeBranch from './ExchangeBranch'
import { LocationPayload } from 'types/location'

type Props = {
  name: string
  searchLocation?: LocationPayload | undefined | null
  onSetBranch?: (branch: Branch | BranchInfoFragment) => void
  isTowExchange: boolean
}

export const Exchange = ({ name, searchLocation, onSetBranch, isTowExchange }: Props) => {
  const branch = useFieldValue(name)

  return (
    <>
      {branch ? (
        <ExchangeBranch name={name} />
      ) : (
        <ExchangeSearch name={name} searchLocation={searchLocation} onSetBranch={onSetBranch} isTowExchange={isTowExchange} />
      )}
    </>
  )
}

export default Exchange
