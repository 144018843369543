import * as React from 'react'
import * as Yup from 'yup'
import { FormApi } from 'final-form'
import { useTranslation } from 'react-i18next'
import * as Ariakit from '@ariakit/react'
import { Heading, Paragraph } from 'components/primitives'
import { EventTypes, LawEnforcementLog } from 'types/events'
import Input from 'components/Input'
import Margin from 'components/Margin'
import Button from 'components/Button'
import { VehicleSelect } from 'components/Select'
import { Form } from 'components/FormManager'
import { useReduxCallStoreActions, useCreateServiceEvent, useCallEvent } from 'hooks/events'
import { pxToRem } from 'theme/utils'
import { isRequired } from 'utils/validators'
import LawEvent from './LawEvent'
import LawSubmit, { SubmitParams } from './LawSubmit'
import { CallTypes, Law, Officer } from 'types/callstore'
import { WorkflowAction } from 'components/Workflow'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentUser, selectCustomVinInput, selectFormsState, selectTicket } from 'redux/appStore'
import { setCurrentEvent } from 'redux/currentEvent/currentEventSlice'

type LawEventAttributes = Law &
  Omit<Officer, 'stateOrProvince'> &
  Pick<LawEnforcementLog, 'name' | 'agent' | 'rentalGPBR' | 'ticketNumber' | 'stateOrProvince' | 'mobilePhone'>

declare module 'types/form' {
  export interface Forms {
    Law: FormApi
  }
}

export const LawForm: React.FC = () => {
  const { t } = useTranslation()
  const { update } = useReduxCallStoreActions()
  const { law } = useSelector(selectFormsState) as any
  const ticketData = useSelector(selectTicket)
  const disclosureStore = Ariakit.useDisclosureStore()
  const currentUser = useSelector(selectCurrentUser)
  const customVinInput = useSelector(selectCustomVinInput)
  const isStoreOpen = disclosureStore.useState('open')

  const createServiceEvent = useCreateServiceEvent<LawEventAttributes>({
    eventType: EventTypes.LAW,
  })
  const lawEvent = useCallEvent(EventTypes.LAW)
  const dispatch = useDispatch()

  const createLawEvent = async (state: SubmitParams) => {
    const { callerName, phone, officer, law } = state

    const { data } = await createServiceEvent({
      agent: currentUser?.name || 'N/A',
      ticketNumber: ticketData.ticketNumber,
      rentalGPBR: ticketData.pickupInfo.groupBranchId,
      name: callerName,
      mobilePhone: phone,
      ...officer,
      stateOrProvince: officer.stateOrProvince.value,
      ...law,
      isActionable: !!law.isActionable,
    })

    if (data) {
      dispatch(setCurrentEvent({ eventType: EventTypes.LAW, newEvent: data }))
    }

    // const attributes = data && data.attributes

    // TODO: Send LAW Email - currently not in use
    // await client.query<LawEmailQuery>({
    //   query: LawEmailDocument,
    //   variables: {
    //     path: '',
    //     input: {
    //       ...attributes,
    //       gpbr: ticketData.pickupInfo.groupBranchId,
    //     },
    //   },
    // })
  }

  if (lawEvent) {
    return <LawEvent {...(lawEvent.event?.attributes as any)} />
  }

  return (
    <Ariakit.DisclosureProvider store={disclosureStore}>
      <Form name="Law" schema={lawSchema} initialValues={law} autoSaveSync={(law) => update({ law })}>
        <Heading as="h5" spacing="md">
          {t('Law')}
        </Heading>
        <VehicleSelect label={`${t('labels.officer.vehicle')}*`} name="vehicle" />
        {customVinInput ? (
          <Input.Hidden name="customVin" defaultValue={customVinInput} />
        ) : (
          <Input.Hidden name="vehicle.value" validate={isRequired(t('required.vehicle'))} />
        )}
        <Margin spacing="md">
          <Input.Checkbox name="isActionable" label={t('Flag as Actionable')} />
        </Margin>
        <Paragraph spacing="md">
          {t('Subpoenas should never be sent via postmail.')}
          {/* we have to add type="button" here because the type property gets overriden by Hidden.Toggle */}
          <Button.Link
            type="button"
            style={{
              padding: 0,
              marginLeft: pxToRem(8),
            }}
            onClick={disclosureStore.toggle}
          >
            ({isStoreOpen ? 'Hide' : 'Show More'})
          </Button.Link>
        </Paragraph>
        <Ariakit.Disclosure store={disclosureStore} style={{ backgroundColor: 'unset', border: 0, padding: 0 }}>
          <Paragraph spacing="md">{t('Fax only to')}:</Paragraph>
          <Paragraph spacing="md">
            Liz Johnson <br />
            600 Corporate Park Dr. <br />
            St. Louis, MO 63105 <br />
          </Paragraph>
          <Paragraph spacing="md">{t('Fax')}: 314-512-4233</Paragraph>
        </Ariakit.Disclosure>
        <Margin spacing="md">
          <WorkflowAction
            callType={CallTypes.RECOVERY}
            eventType={EventTypes.RECOVERY}
            label={t('recovery.addVehicleRecovery')}
          />
        </Margin>
        <Input.Textarea label={t('labels.additionalNotes')} name="notes" />
        <LawSubmit onSubmit={createLawEvent}>{t('Submit Law Enforcement Inquiry')}</LawSubmit>
      </Form>
    </Ariakit.DisclosureProvider>
  )
}

const lawSchema = Yup.object()

export default LawForm
